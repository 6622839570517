import React from "react"
import { graphql, Link } from "gatsby"
import { getFluidGatsbyImage } from "../components/getFluidGatsbyImage"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Img from "gatsby-image"
import SEO from "../components/seo"

import { TemplateStyle } from "../styles/templateProj"

export const query = graphql`
  query($slug: String!) {
    contentfulCarouselNews(slug: { eq: $slug }) {
      name
      date(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
      contentBlock {
        json
      }
      slug
      image {
        file {
          url
        }
      }
    }
    bg: dataJson {
      projectos {
        img {
          publicURL
        }
      }
    }
  }
`
const NewsTemplate = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file, title } = node.data.target.fields
        const image = {
          file: file["pt"],
        }
        const fluidProps = getFluidGatsbyImage(image, { maxWidth: 720 })
        return <Img fluid={fluidProps} alt={title["pt"]} />
      },
    },
  }

  return (
    <>
      <SEO
        title={props.data.contentfulCarouselNews.name}
        pathname={`/${props.data.contentfulCarouselNews.slug}`}
        image={props.data.contentfulCarouselNews.image.file.url}
      />
      <TemplateStyle>
        <div
          className="main-container"
          style={{
            backgroundImage: `url(${props.data.bg.projectos.img.publicURL})`,
          }}
        >
          <div className="blog-container">
            <Link to="/noticias">
              <h4 className="linkBack">
                <i className="material-icons">arrow_back</i> Voltar a notícias
              </h4>
            </Link>
            <h1 className="title">{props.data.contentfulCarouselNews.name}</h1>
            <p className="date">{props.data.contentfulCarouselNews.date}</p>
            <div className="post-news">
              {documentToReactComponents(
                props.data.contentfulCarouselNews.contentBlock.json,
                options
              )}
            </div>
          </div>
        </div>
      </TemplateStyle>
    </>
  )
}

export default NewsTemplate
